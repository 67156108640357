<template>
    <table>
        <colgroup>
          <col width="130px"><col width="170px"><col width="130px"><col width="170px">
          <col width="130px"><col width="170px"><col width="130px"><col width="170px">
        </colgroup>
        <select class="wid200" v-model="regParams.oprUno" @change="integratedSearch(3)">
            <option value="A">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="rteInfo in UnoList" :key="rteInfo" :value="rteInfo">{{ rteInfo }}</option>
          </select>
        <thead>
          <tr class = "ETA_ETD_under_table_tr">
            <th> VSL/VOY(LO) </th>
            <th> Schedule ETA(LO) </th>
            <th> VSL/VOY(LO) </th>
            <th> AIS ETA(LO) </th>
            <th> VSL/VOY(LO) </th>
            <th> Schedule ETD(LO) </th>
            <th> VSL/VOY(LO) </th>
            <th> AIS ETD(LO) </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in parentInfo" style="height : 25px"
            :key="idx">
              <td>{{ item.tableVslCdA }} {{ item.tableVoyNoA }}</td><td>{{ item.tableSchEta }}</td>
              <td style="color: #008000">{{ item.tableVslCdC }} {{ item.tableVoyNoC }}</td>
              <td style="color: #008000; border-right: 1px solid #e1e3eb;">{{ item.tableAisEta }}</td>
              <td>{{ item.tableVslCdB }} {{ item.tableVoyNoB }}</td><td>{{ item.tableSchEtd }}</td>
              <td style="color: #008000">{{ item.tableVslCdD }} {{ item.tableVoyNoD }}</td>
          </tr>
        </tbody>
      </table>
  </template>

<script>
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo2'
export default {
  name: 'DataAnalysis',
  props: {
    parentInfo: {
      type: Array,
      default: () => {
        return {}
      }
    }
  },
  data () {
  return {
    regParams: {
        nvgType: 'A',
        vslType: 'A',
        Type: true,
        rteCd: 'A',
        oprUno: 'A'
      }
    }
  }
}
  </script>
